import { useState, useEffect } from 'react';

import useApp from './useApp';
import useGQL from './useGQL';

const useRelated = () => {
	const { appDispatch } = useApp();

	// Query
	const [variables, setVariables] = useState({});

	const [result, setResult] = useState(null); // last result
	const [error, setError] = useState(null); // last error
	const { query } = useGQL('auction>related_items');
	const { isFetching, isSuccess, data, refetch } = query(variables, { enabled: false });

	const get = v => setVariables(v);

	useEffect(() => {
		if (Object.keys(variables).length > 0 && isFetching === false) {
			refetch();
		}
	}, [variables]);

	useEffect(() => {
		if (isFetching === false && isSuccess === true) {
			setResult((data && data.request) ? data.request : null);
			setError(data.error ? data.message : null);
		}
	}, [isFetching]);

	// Mutation
	const { mutation: mutateSetRelation } = useGQL('auction>related');
	const { mutation: mutateSavedRemoveAll } = useGQL('auction>saved_remove_all');

	const setRelation = (v, c) => {
		mutateSetRelation(v).then(({ request }) => {
			if (c) c(request, null);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	// Remove all saved (relation SAVED & all subscriptions)
	const savedRemoveAll = (v, c) => {
		mutateSavedRemoveAll(v).then(({ request }) => {
			if (c) c(request, null);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	return { get, result, error, setRelation, savedRemoveAll };
};

export default useRelated;
