import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import styles from './no-result.module.scss';

const NoResult = ({ children, title, href, hrefText }) => {
	return (
		<div className={styles.default}>
			{title && <h2>{title}</h2>}
			<p>{children}</p>
			{href && <Link href={href}><a className="btn primary">{hrefText}</a></Link>}
		</div>
	);
};

NoResult.defaultProps = {
	href: null,
	hrefText: '',
	title: null
};

NoResult.propTypes = {
	children: PropTypes.node.isRequired,
	href: PropTypes.string,
	hrefText: PropTypes.string,
	title: PropTypes.string
};

export default NoResult;
